import {afterNextRender, Injectable} from '@angular/core'
import {KeycloakService} from 'keycloak-angular'

import {PlatformService} from '@chatize-ui/services/platform.service'
import {AuthState} from '@chatize-ui/schemes/components/auth-state'

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _state: AuthState

  constructor(
    private platformService: PlatformService,
    private keycloakService: KeycloakService,
  ) {
  }

  initState(): void {
    if (this.platformService.isServer) return

    afterNextRender(() => {
      this._state = this.keycloakService.isLoggedIn() ? AuthState.LoggedIn : AuthState.NotLoggedIn
    })
  }

  login(): void {
    this.keycloakService.login()
  }

  get state(): AuthState {
    return this._state
  }
}
