import {AppCode} from '@chatize-ui/constants/app-code'
import {AppMode} from '@chatize-ui/constants/app-mode'

export const HOST_MAIN_DEV = 'http://localhost:4200'
export const HOST_MAIN_QA = 'https://qa.chatize.com'
export const HOST_MAIN_PROD = 'https://www.chatize.com'

export const HOST_PRODUCTS_DEV = 'http://localhost:4201'
export const HOST_PRODUCTS_QA = 'https://products-qa.chatize.com'
export const HOST_PRODUCTS_PROD = 'https://products.chatize.com'

export const HOST_ABOUT_DEV = 'http://localhost:4202'
export const HOST_ABOUT_QA = 'https://about-qa.chatize.com'
export const HOST_ABOUT_PROD = 'https://about.chatize.com'

export const HOSTS: Record<AppCode, Record<AppMode, string>> = {
  [AppCode.Main]: {
    [AppMode.Dev]: HOST_MAIN_DEV,
    [AppMode.Qa]: HOST_MAIN_QA,
    [AppMode.Prod]: HOST_MAIN_PROD,
  },
  [AppCode.Products]: {
    [AppMode.Dev]: HOST_PRODUCTS_DEV,
    [AppMode.Qa]: HOST_PRODUCTS_QA,
    [AppMode.Prod]: HOST_PRODUCTS_PROD,
  },
  [AppCode.About]: {
    [AppMode.Dev]: HOST_ABOUT_DEV,
    [AppMode.Qa]: HOST_ABOUT_QA,
    [AppMode.Prod]: HOST_ABOUT_PROD,
  },
}
