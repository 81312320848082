import {Environment} from '@chatize-ui/schemes/environment'
import {AppMode} from '@chatize-ui/constants/app-mode'
import {AppCode} from '@chatize-ui/constants/app-code'
import {HOSTS} from '@chatize-ui/constants/hosts'

export class Config {

  private _environment: Environment

  constructor(
    environment: Environment,
  ) {
    this._environment = environment
  }

  get environment(): Environment {
    return this._environment
  }

  get appMode(): AppMode {
    return this.environment.appMode
  }

  get appCode(): AppCode {
    return this.environment.appCode
  }

  get appHost(): string {
    return HOSTS[this.appCode][this.appMode]
  }

  get apiUrl(): string {
    return this.environment.apiUrl
  }

  get languages(): string[] {
    return this.environment.languages
  }

  get keycloakConfig(): any {
    return this.environment.keycloakConfig
  }

  get listenerInterval(): number {
    return this.environment.listenerInterval
  }
}
